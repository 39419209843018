import { useState } from 'react';

export const Logo = ({
  classNames,
  testId,
}: {
  classNames: string;
  testId: string;
}) => {
  const [showChangelog, setShowChangelog] = useState(false);

  return (
    <>
      <div
        className={classNames}
        data-testid={testId}
        onClick={() => setShowChangelog(!showChangelog)}
      >
        <svg
          viewBox="0 0 300 300"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M150 45.1596C92.0983 45.1596 45.1596 92.0983 45.1596 150C45.1596 207.902 92.0983 254.84 150 254.84C207.902 254.84 254.84 207.902 254.84 150C254.84 92.0983 207.902 45.1596 150 45.1596ZM13 150C13 74.337 74.337 13 150 13C225.663 13 287 74.337 287 150C287 225.663 225.663 287 150 287C74.337 287 13 225.663 13 150Z"
            fill="#0063D3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M150.5 210C183.913 210 211 183.137 211 150C211 138.502 207.739 127.759 202.083 118.632C198.635 124.406 192.134 128.298 184.683 128.298C173.624 128.298 164.66 119.725 164.66 109.149C164.66 103.011 167.68 97.5469 172.376 94.0426C165.592 91.432 158.215 90 150.5 90C117.087 90 90 116.863 90 150C90 183.137 117.087 210 150.5 210Z"
            fill="#0063D3"
          />
        </svg>
      </div>
    </>
  );
};
