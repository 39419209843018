import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { Dispatch, Fragment, ReactElement, SetStateAction } from 'react';
import Button from '~/features/common/components/buttons/Button/index';
import Icon from '~/features/common/components/icons/Icon/index';
import { cn } from '~/features/common/utils/tailwind';

export default function ModalConfirm({
  title,
  children,
  open,
  setOpen,
  onConfirm,
  onCancel,
  confirmButtonLabel = 'Save',
  disableSaveButton,
  successButton,
  primaryButton,
  removeCancelButton,
  isSaving,
  modalGrowHorizontal,
  modalClassName,
}: {
  title?: string;
  children?: ReactElement | ReactElement[];
  open?: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onConfirm: () => void | Promise<void>;
  onCancel?: () => void | Promise<void>;
  confirmButtonLabel?: string;
  disableSaveButton?: boolean;
  successButton?: boolean;
  primaryButton?: boolean;
  removeCancelButton?: boolean;
  isSaving?: boolean;
  modalGrowHorizontal?: boolean;
  modalClassName?: string;
}) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed inset-0 z-50 overflow-y-auto"
        open={open}
        onClose={setOpen}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pb-20 pt-4 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {isSaving ? (
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            ) : (
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            )}
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:h-screen sm:align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              data-testid="confirmation-modal"
              className={cn(
                'inline-block transform rounded-lg bg-white px-4 pb-4 pt-5 text-left align-bottom shadow-xl transition-all dark:bg-black sm:my-8 sm:p-6 sm:align-middle',
                { 'sm:w-full sm:max-w-3xl': !modalGrowHorizontal },
                modalClassName
              )}
            >
              <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                {isSaving ? (
                  <></>
                ) : (
                  <button
                    type="button"
                    className="flex h-5 w-5 items-center justify-center rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 dark:bg-black dark:text-gray-300 dark:hover:text-gray-50"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <Icon icon={faTimes} />
                  </button>
                )}
              </div>
              <div className="sm:flex sm:justify-start">
                <div className="mt-3 w-full text-center sm:mt-0 sm:text-center">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-50"
                  >
                    {title}
                  </Dialog.Title>
                  <div className="mt-4">{children}</div>
                </div>
              </div>
              <div className="flex justify-center">
                <div className="mt-5 flex sm:mt-4">
                  <div className="w-fit">
                    <Button
                      type="submit"
                      className={clsx(
                        'mr-2 bg-red-500 text-white hover:bg-red-600 dark:bg-red-500 dark:text-white dark:hover:bg-red-600',
                        successButton &&
                          'bg-green-500  hover:bg-green-600 dark:bg-green-500  dark:hover:bg-green-600',
                        primaryButton &&
                          'bg-primary  hover:bg-blue-600 dark:bg-primary  dark:hover:bg-blue-600'
                      )}
                      onClick={onConfirm}
                      disabled={disableSaveButton ?? false}
                      data-testid="delete-btn"
                      isLoading={isSaving ? true : false}
                    >
                      {confirmButtonLabel}
                    </Button>
                  </div>
                  {!removeCancelButton && (
                    <div className="w-20">
                      <Button
                        type="button"
                        className="mr-2 "
                        onClick={() => {
                          if (onCancel) {
                            onCancel();
                          }
                          setOpen(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
